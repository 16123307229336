import React from 'react';
import { TemplateIcon } from '@assets/icons';
import { configureTemplatesClient } from '@data/clients';
import {
	AsyncLazyEditPropertiesExtension,
	AsyncLazyLoadTemplateByTag,
} from '@extensions';
import {
	getDefaultDrawerProps,
	getDefaultModalProps,
	SaveAsTemplateOverlay,
	SelectTemplateOverlay,
	TemplateDetailsOverlay,
	TemplateDuplicateOverlay,
	TemplatePropertiesOverlay,
	UseTemplateOverlay,
} from '@overlays';
import { templatesListPath } from '@routes';
import {
	internalTemplatesPiletOverlays,
	templatesPiletExtensionSlots,
	templatesPiletOverlays,
} from '@sdk/extensionTypes';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { t, withDefaultProviders } from '@utils';
import { Skeleton } from 'antd';
import { AsyncBlockDevelopmentPage } from './blockDevelopment';
import { registerBlocks } from './blocks';
import { FeatureFlag } from './featureFlagDefinitions';
import { setLogger } from './logger';
import {
	checkRolesAndPreferences,
	RolesAndProvisioningRequirements,
} from './provisioning';

const LazyRenderConditionally = React.lazy(
	() => import('./provisioning/RenderConditionally')
);
const AsyncLazyRenderConditionally = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyRenderConditionally {...props} />
	</React.Suspense>
);

const LazyManagementPage = React.lazy(() => import('./pages/ManagementPage'));
const AsyncLazyManagementPage = () => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyManagementPage />
	</React.Suspense>
);

const getRolesProvisioningRequirements = () => {
	const requirements: RolesAndProvisioningRequirements = {
		requiredRoles: ['Employee'],
		requiredFeatureFlags: [FeatureFlag.EnableTemplateManagement],
		requiredASTs: ['EnableTemplates'],
	};

	return requirements;
};

export function setup(piletApi: PiletApi) {
	setLogger(piletApi.sf.getLogger());
	configureApiClient(piletApi);
	registerNavigationEntries(piletApi);
	registerPages(piletApi);
	registerOverlays(piletApi);
	registerInternalOverlays(piletApi);
	registerExtensions(piletApi);
	registerBlocks(piletApi);
	if (process.env.NODE_ENV === 'development') {
		registerDeveloperTools(piletApi);
	}
}

function configureApiClient(piletApi: PiletApi) {
	configureTemplatesClient(piletApi);
}

function registerNavigationEntries(piletApi: PiletApi) {
	piletApi.sf.registerLeftNavComponent({
		href: templatesListPath,
		title: () => t('templates-pilet:titles.dashboard') ?? '...',
		isAvailable: () =>
			checkRolesAndPreferences(piletApi, getRolesProvisioningRequirements()),
		weight: 200,
		icon: TemplateIcon,
	});
}

function registerPages(piletApi: PiletApi) {
	piletApi.registerPage(
		templatesListPath,
		withDefaultProviders(piletApi, props => (
			<AsyncLazyRenderConditionally
				{...props}
				piletApi={piletApi}
				requirements={getRolesProvisioningRequirements()}
				redirectToDashboard
			>
				<AsyncLazyManagementPage />
			</AsyncLazyRenderConditionally>
		))
	);
}

function registerOverlays(piletApi: PiletApi) {
	piletApi.registerModal(
		templatesPiletOverlays.selectTemplate,
		withDefaultProviders(piletApi, props => (
			<SelectTemplateOverlay {...props} {...props.options} />
		)),
		getDefaultModalProps({
			isFullscreen: true,
			titleText: t('prompts.select_a_template'),
		})
	);

	piletApi.registerModal(
		templatesPiletOverlays.useTemplate,
		withDefaultProviders(piletApi, props => (
			<UseTemplateOverlay {...props} {...props.options} />
		)),
		getDefaultModalProps({
			isFullscreen: true,
			titleText: t('prompts.use_a_template'),
		})
	);

	piletApi.registerDrawer(
		templatesPiletOverlays.useTemplateDrawer,
		withDefaultProviders(piletApi, props => (
			<UseTemplateOverlay
				{...props}
				templateRID={props.options.params.templateRID}
				containerRID={props.options.params.containerRID}
				resourceType={props.options.params.resourceType}
				onCompleteNavigationPath={props.options.params.onCompleteNavigationPath}
				tags={props.options.params.tags}
				context={props.options.params.context}
				buttonText={props.options.params.buttonText}
				cancelButtonText={props.options.params.cancelButtonText}
				showTemplateTitle={props.options.params.showTemplateTitle}
				requiredResponseObj={props.options.params?.requiredResponseObj || false}
				isDrawer={props.options?.params?.isDrawer || false}
				showActionableMessage={props.options.params.showActionableMessage}
			/>
		)),
		getDefaultDrawerProps({
			titleText: t('prompts.use_a_template'),
		})
	);

	piletApi.registerModal(
		templatesPiletOverlays.saveAsTemplate,
		withDefaultProviders(piletApi, props => (
			<SaveAsTemplateOverlay {...props} {...props.options} />
		)),
		getDefaultModalProps({
			isFullscreen: false,
			titleText: t('prompts.save_as_template'),
		})
	);
}

function registerInternalOverlays(piletApi: PiletApi) {
	piletApi.registerDrawer(
		internalTemplatesPiletOverlays.templateDetails,
		withDefaultProviders(piletApi, props => (
			<TemplateDetailsOverlay {...props} {...props.options} />
		)),
		getDefaultDrawerProps({
			titleText: t('prompts.use_a_template'),
			width: '736px', // override large size
		})
	);

	piletApi.registerDrawer(
		internalTemplatesPiletOverlays.templateProperties,
		withDefaultProviders(piletApi, props => (
			<TemplatePropertiesOverlay {...props} {...props.options} />
		)),
		getDefaultDrawerProps({
			titleText: t('common.template_properties'),
			zIndex: 15,
			width: '400px',
			placement: 'left',
		})
	);

	piletApi.registerDrawer(
		internalTemplatesPiletOverlays.templateDuplicate,
		withDefaultProviders(piletApi, props => (
			<TemplateDuplicateOverlay {...props} {...props.options} />
		)),
		{
			drawerProps: {
				...getDefaultDrawerProps({
					titleText: t('common.duplicate'),
					size: 'default',
					width: '378px',
				}).drawerProps,
				destroyOnClose: true,
			},
		}
	);
}

function registerExtensions(piletApi: PiletApi) {
	piletApi.registerExtension(
		templatesPiletExtensionSlots.editProperties,
		withDefaultProviders(piletApi, ({ params }) => (
			<AsyncLazyEditPropertiesExtension piletApi={piletApi} {...params} />
		))
	);
	piletApi.registerExtension(
		templatesPiletExtensionSlots.runTemplateWithTag,
		withDefaultProviders(piletApi, ({ params }) => (
			<AsyncLazyLoadTemplateByTag piletApi={piletApi} {...params} />
		))
	);
}

function registerDeveloperTools(piletApi: PiletApi) {
	const devPageParentHref = '/templates-dev';
	piletApi.sf.registerLeftNavComponent({
		href: devPageParentHref,
		title: () => 'Templates Pilet Dev',
		icon: TemplateIcon,
		weight: 50,
	});

	piletApi.sf.registerLeftNavChildComponent({
		parent: devPageParentHref,
		href: `${devPageParentHref}/block-development`,
		title: () => 'Block development',
	});

	piletApi.sf.registerLeftNavChildComponent({
		parent: devPageParentHref,
		href: `${devPageParentHref}/extensions`,
		title: () => 'Extensions',
	});

	piletApi.registerPage(`${devPageParentHref}/block-development`, () => (
		<AsyncBlockDevelopmentPage piletApi={piletApi} />
	));
}
